<template>
  <div class="PoInfo">
    <Header back="返回" title="政策资讯" index="首页" titleOne="政策管理" titleTwo="政策咨询" beforeTitle="详情" />
    <div class="content">
      <h1>{{ title }}</h1>
      <h2>
        {{ area }}
        &nbsp;&nbsp;&nbsp;
        <span v-if="classify == 1">科技政策</span>
        <span v-if="classify == 2">科技项目</span>
        <span v-if="classify == 3">立项公告</span>
        <span v-if="classify == 4">申报通知</span>
        &nbsp;&nbsp;&nbsp;
        {{ effective_time1 ? effective_time1:'-' }} 至 {{
        effective_time2 ? effective_time2:'-'
        }}
        &nbsp;&nbsp;&nbsp;
        {{ organization }}
        &nbsp;&nbsp;&nbsp;
        <el-link :href="location" target="_blank" type="primary" :underline="false">原文链接</el-link>
        <!-- {{ location }} -->
      </h2>
      <p v-html="content_html"></p>
      <div class="file_box" v-if="file_list.length > 0">
        <h1>附件</h1>
        <span style="color: #A6ABC7;">点击附件名称，可下载到本地</span>
        <div v-for="(item,index) in file_list" :key="index">
          <el-link
            type="primary"
            style="font-weight: bold;margin-top: 15px;"
            :href="link + item.url"
            target="_blank"
          >{{ item.name }}</el-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import site from '../../components/global.vue'
export default {
  data () {
    return {
      link: site.userSite,
      title: "",
      area: "",
      effective_time1: "",
      effective_time2: "",
      content: "",
      organization: "",
      location: "",
      desc: "",
      classify: "",
      content_html: "",
      file_list: [],
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/policy/policy_detail", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          console.log(res);
          this.title = res.data.title;
          this.area = res.data.area;
          this.effective_time1 = res.data.effective_time1;
          this.effective_time2 = res.data.effective_time2;
          this.content_html = res.data.content;
          this.organization = res.data.organization;
          this.location = res.data.location;
          this.classify = res.data.classify;
          this.file_list = res.data.file_list
        });
    }
  },
  methods: {},
};
</script>

<style scoped="scoped">
.PoInfo h2 {
  color: #a6abc7;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.PoInfo p {
  color: #000000;
  line-height: 25px;
}
.file_box {
  border-top: 1px solid #d8dce6;
}
</style>
